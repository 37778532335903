// This is the scss entry file
import "../styles/index.scss";

// We can import Bootstrap JS instead of the CDN link, if you do not use
// Bootstrap, please feel free to remove it.
import "bootstrap/dist/js/bootstrap.bundle";

// We can import other JS file as we like
window.document.addEventListener("DOMContentLoaded", function () {
   'use strict';

   function toggle_manufacturer_model(vehicle_manufacturer, form_vehicle_model) {
      let manufacturer = vehicle_manufacturer.value.toLowerCase().replace(/\s/g, "");
      if (manufacturer == 'skoda' || manufacturer == 'škoda') {
         form_vehicle_model.classList.remove("d-none");
      } else {
         form_vehicle_model.classList.add("d-none");
      }
   }

   var form_vehicle_model = window.document.getElementById("form-vehicle-model");
   if (form_vehicle_model) {
      let vehicle_manufacturer = window.document.getElementById('id_vehicle_manufacturer');

      vehicle_manufacturer.addEventListener("change", function () {
         toggle_manufacturer_model(vehicle_manufacturer, form_vehicle_model);
      });
      vehicle_manufacturer.addEventListener("keyup", function () {
         toggle_manufacturer_model(vehicle_manufacturer, form_vehicle_model);
      });
      window.addEventListener("load", function () {
         toggle_manufacturer_model(vehicle_manufacturer, form_vehicle_model);
      });
   }

});
